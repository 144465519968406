<template>
    <app-layout :container="false" :navLinks="navLinks">
        <!-- Buffer -->
        <div class="pt-10"></div>
        <!-- Main Hero -->
        <section style="background: linear-gradient(180deg, #171717 50%, #e8e8e9 50%);">
            <div class="min-w-0 px-4 sm:px-6 xl:px-8 md:container md:mx-auto">
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-4" >
                    <div class="self-end mb-10 text-center md:text-left">
                        <h1 class="text-5xl font-bold">Take charge of your privacy</h1>
                        <h2 class="text-subtitle mt-2 sm:mt-0">
                            Your online activity is constantly monitored by big tech, malicious hackers,
                            and even your own internet service provider.
                        </h2>
                    </div>
                    <div class="md:row-span-2">
                        <img
                            draggable="false"
                            class="mx-auto xl:w-11/12"
                            src="images/client.png"
                        />
                    </div>
                    <div class="text-black mt-10">
                        <h1 class="text-5xl font-bold">Protection at all costs</h1>
                        <h2 class="text-subtitle">
                            Stop sacrificing quality for currency.<br>
                            Secure your digital life by using a virtual private network.
                        </h2>
                    </div>
                </div>
            </div>
        </section>
        <div class="bg-asoc-white">
            <div class="min-w-0 px-4 sm:px-6 xl:px-8 md:container md:mx-auto">
                <section>
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 2xl:-mt-20 mb-4 lg:mb-0" >
                        <div class="flex flex-col mt-4 xl:mt-0 xl:items-start row-span-full">
                            <div class="w-11/12 sm:w-9/12">
                                <app-button buttonStyle="big" is="router" href="/checkout">
                                    <div>
                                        Get aSocket Now
                                    </div>
                                </app-button>
                                <ul class="flex flex-row justify-center mt-4 space-x-3">
                                    <li>
                                        <img draggable="false" class="w-10" src="/images/branding/os/windows.svg">
                                    </li>
                                    <li>
                                        <img draggable="false" class="w-10 opacity-50 cursor-wait"
                                        src="/images/branding/os/apple.svg">
                                    </li>
                                    <li>
                                        <img draggable="false" class="w-10 opacity-50 cursor-wait"
                                        src="/images/branding/os/linux.svg">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- Globe Location -->
                <home-globe id="location" />
                <!-- Features -->
                <home-features id="features" />
                <!-- Pricing -->
                <home-pricing id="pricing" />
                <!-- FAQ -->
                <home-faq id="faq" />
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout.vue';
import AppButton from '@/components/AppButton.vue';
import HomeGlobe from './HomeGlobe.vue';
import HomeFeatures from './HomeFeatures.vue';
import HomePricing from './HomePricing.vue';
import HomeFaq from './HomeFAQ.vue';

export default {
    name: 'Home',
    components: {
        AppLayout,
        AppButton,
        HomeGlobe,
        HomeFeatures,
        HomePricing,
        HomeFaq,
    },
    inject: ['user'],
    data() {
        return {
            navLinks: {
                Features: {
                    type: 'scroll',
                    link: 'features',
                },
                Pricing: {
                    type: 'scroll',
                    link: 'pricing',
                },
                FAQ: {
                    type: 'scroll',
                    link: 'faq',
                },
                Contact: {
                    type: 'router',
                    link: '/contact',
                },
            },
        };
    },
};
</script>
