import { admin } from '../middleware/auth';

export default [
    {
        path: '/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ '../../views/Admin/Index.vue'),
        children: [
            {
                path: '',
                name: 'admin-home',
                component: () => import(/* webpackChunkName: "admin" */ '../../views/Admin/Home/Index.vue'),
            },
            {
                path: 'users',
                name: 'admin-users',
                component: () => import(/* webpackChunkName: "admin" */ '../../views/Admin/Users/Index.vue'),
                meta: {
                    title: 'Users',
                },
            },
            {
                path: 'users/:id',
                name: 'admin-users-show',
                component: () => import(/* webpackChunkName: "admin" */ '../../views/Admin/Users/Show.vue'),
                meta: {
                    title: 'User Show',
                },
            },
            {
                path: 'sandbox',
                name: 'admin-sandbox',
                component: () => import(/* webpackChunkName: "admin" */ '../../views/Admin/Sandbox/Index.vue'),
                meta: {
                    title: 'Sandbox',
                },
            },
        ],
        beforeEnter: admin,
        meta: {
            title: 'Admin',
        },
    },
];
