<template>
    <a :href="href" :class="classes" v-if="is === 'link'" target="_blank" v-bind="$attrs">
        <slot />
    </a>

    <a
        :class="classes"
        @click="scrollTo(href)"
        v-if="is === 'scroll'"
        v-bind="$attrs"
    >
        <slot />
    </a>

    <router-link :to="href" :exact-active-class="activeClass" :class="classes" v-if="is === 'router'" v-bind="$attrs">
        <slot />
    </router-link>
</template>

<script>
export default {
    props: {
        is: {
            type: String,
            default: 'link',
        },
        active: {
            type: Boolean,
            default: false,
        },
        activeClass: {
            type: String,
            default: '',
        },
        href: {
            type: String,
            default: null,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            if (this.mobile) {
                return this.active
                    ? 'block pl-3 pr-4 py-2 font-semibold text-white cursor-pointer transition duration-150 ease-in-out'
                    : 'block pl-3 pr-4 py-2 font-semibold text-white cursor-pointer hover:text-gray-300 focus:text-gray-400 transition duration-150 ease-in-out';
            }
            return this.active
                ? 'inline-flex items-center font-semibold text-white cursor-pointer transition duration-150 ease-in-out'
                : 'inline-flex items-center font-semibold text-white cursor-pointer hover:text-gray-300 focus:text-gray-400 transition duration-150 ease-in-out';
        },
    },
    methods: {
        scrollTo(href) {
            document.getElementById(href).scrollIntoView({ behavior: 'smooth' });
        },
    },
};
</script>
