import ToastComponent from './Component.vue';
import { createComponent } from './helpers';

const useToast = (globalProps = {}) => ({
    open(options) {
        let message = null;
        let defaultProps = {};
        // Check if message is the only option
        if (typeof options === 'string') {
            message = options;
            defaultProps = { message };
        } else {
            defaultProps = { ...options };
        }
        // Create our prop data
        const propsData = { ...defaultProps, ...globalProps };
        const instance = createComponent(ToastComponent, propsData, document.body);

        return {
            dismiss: instance.ctx.dismiss,
        };
    },
    success(message, options = {}) {
        return this.open({
            message,
            type: 'success',
            ...options,
        });
    },
    error(message, options = {}) {
        return this.open({
            message,
            type: 'error',
            ...options,
        });
    },
    info(message, options = {}) {
        return this.open({
            message,
            type: 'info',
            ...options,
        });
    },
    warn(message, options = {}) {
        return this.open({
            message,
            type: 'warn',
            ...options,
        });
    },
});

export default useToast;
