<template>
    <section class="mt-20">
        <h1 class="text-5xl font-bold mb-4 text-center text-black">Frequently Asked Questions</h1>
        <div class="flex justify-center">
            <div class="flex-grow max-w-xl text-black">
                <div class="space-y-4 mb-16">
                    <app-accordion :accordions="accordions"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AppAccordion from '@/components/AppAccordion.vue';

export default {
    components: {
        AppAccordion,
    },
    data() {
        return {
            accordions: [
                {
                    title: 'What is a Virtual Private Network (VPN)?',
                    content: 'A Virtual Private Network (VPN) is used to protect your internet connection, data, and activity to provide you with privacy online.<br><br>When connected to a VPN, all of your internet traffic is routed through an encrypted tunnel that nobody can view into, including hackers, your internet service provider, and even the government - ultimately concealing your online activity, masking your identity, and hiding your IP address.<br><br>Using a VPN allows you to protect your privacy, increase your security, unlock restricted content, change your location, change your IP address, and most importantly, stay safe online.',
                },
                {
                    title: 'What kind of logs are recorded?',
                    content: '<b>We have a strict no-logs policy</b>. Unlike other VPN providers who claim to have a no-logs policy but actually place cookies into your browser to track your activity, we keep no user activity logs, period.<br><br>Forget the fine print - for the sake of transparency, we record the following information to ease user accessibility/management, ensure account security, prevent abuse, and dispute unfair chargebacks: hardware identifier (hashed), operating system, device model, machine name, and timestamp of token validation. The IP address is temporarily stored on the machine the user is connected to and is automatically deleted when a session is terminated by the user, or within at least 1 hour of user inactivity.<br><br><i>With that being said, we do not monitor, record, log, or store used bandwidth, traffic logs, browsing activity, or any other form of user data.</i>',
                },
                {
                    title: 'What platforms do you support?',
                    content: 'Our VPN client is currently available on <b>Windows</b> with <b>macOS</b> and <b>Linux</b> soon to follow. iOS and Android are planned for the future.',
                },
                {
                    title: 'What locations do you offer?',
                    content: 'As of August 16th, 2022, we have 30 different locations. We offer virtual private network connections to <b>Los Angeles, California</b>; <b>New York, New York</b>; <b>Dallas, Texas</b>; <b>London, United Kingdom</b>; <b>Tokyo, Japan</b>; and <b>Amsterdam, Netherlands</b>. <p class="mt-4 italic">We have plans to expand into additional countries within the future.</p>',
                },
                {
                    title: 'Still have questions?',
                    content: 'If you have any other questions, don\'t hesitate to <u><b><a href="https://asocket.net/contact">contact us</a></b></u>!',
                },
            ],
        };
    },
};
</script>
