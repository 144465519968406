import { createApp, h } from 'vue';
// Plugins
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toast from './plugins/toast';
// Application
import App from './App.vue';
import router from './router';
import store from './store';
import Error from './views/Error.vue';
// CSS
import './assets/tailwind.css';
// Axios
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const token = localStorage.getItem('userToken');
if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}
// Get user from API
store.dispatch('getUser')
    .then(() => {
        const app = createApp(App)
            .use(VueAxios, axios)
            .use(Toast, {
                queue: true,
            })
            .use(store)
            .use(router);

        /**
         * Used until Vue 3.3
         * https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity
         */
        app.config.unwrapInjectedRef = true;

        app.mount('#app');
    })
    .catch((err) => {
        console.log(err);
        // Create a fallback app
        createApp({
            render: () => h(Error, {
                status: 502,
            }),
        }).mount('#app');
    });
