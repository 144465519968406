<template>
    <div :class="[maxWidthClass, posClass]">
        <div :class="[styleClass, 'overflow-hidden rounded-lg p-6']">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        height: {
            type: String,
        },
        maxWidth: {
            type: String,
            default: '2xl',
        },
        posClass: {
            type: String,
        },
        color: {
            type: String,
            default: 'bg-asoc-black',
        },
        extraStyleClass: {
            type: String,
        },
        shadow: {
            type: String,
            default: 'shadow-xl',
        },
    },
    computed: {
        maxWidthClass() {
            return {
                sm: 'sm:max-w-sm',
                md: 'sm:max-w-md',
                lg: 'sm:max-w-lg',
                xl: 'sm:max-w-xl',
                '2xl': 'sm:max-w-2xl',
                full: 'sm:max-w-full',
            }[this.maxWidth];
        },
        styleClass() {
            return [
                this.height,
                this.color,
                this.extraStyleClass,
                this.shadow,
            ].join(' ');
        },
    },
};
</script>
