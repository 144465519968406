<template>
    <div class="flex bg-asoc-black h-screen">
        <div class="m-auto">
            <app-logo class="mx-auto animate-bounce mb-1 h-20 w-20" />
            <app-segment maxWidth="md" class="text-center" color="bg-asoc-white">
                <h1 class="text-5xl text-asoc-black font-bold">{{ title }}</h1>
                <p class="text-2xl text-asoc-black font-semibold mt-2">
                    {{ description }}
                </p>
                <div class="mt-4" v-if="![500, 501, 502, 503, 504].includes(status)">
                    <app-button @click="$router.push('/')">
                        Go Home
                    </app-button>
                </div>
            </app-segment>
        </div>
    </div>
</template>

<script>
import AppButton from '@/components/AppButton.vue';
import AppLogo from '@/components/AppLogo.vue';
import AppSegment from '@/components/AppSegment.vue';

export default {
    components: {
        AppButton,
        AppLogo,
        AppSegment,
    },
    props: {
        status: {
            type: Number,
        },
    },
    computed: {
        title() {
            return {
                0: 'Coming Soon',
                502: '502: Bad Gateway',
                503: '503: Service Unavailable',
                500: '500: Server Error',
                404: '404: Not Found',
                403: '403: Forbidden',
            }[this.status];
        },
        description() {
            return {
                0: 'This feature is currently disabled, come back soon!',
                502: 'Sorry, a connection cannot be made to our API.',
                503: 'Sorry, we are doing some maintenance. Please check back soon.',
                500: 'Whoops, something went wrong on our servers.',
                404: 'Sorry, the page you are looking for could not be found.',
                403: 'Sorry, you are forbidden from accessing this page.',
            }[this.status];
        },
    },
};
</script>
