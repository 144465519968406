<template>
    <div>
        <div
            :class="[`rounded-md py-4 px-6 cursor-pointer shadow-lg`, colorClass]"
            @click="toggle"
        >
            <div class="flex justify-between">
                <h1 class="text-xl font-semibold">
                    <slot name="title" />
                </h1>
                <svg
                    ref="chevron"
                    viewBox="0 0 16 16"
                    :class="['w-6 transform transition-transform duration-200 ease-in-out', svgClass]"
                >
                    <path
                        d="M3.22 9.78a.75.75 0 010-1.06l4.25-4.25a.75.75 0
                        011.06 0l4.25 4.25a.75.75 0 01-1.06 1.06L8 6.06 4.28 9.78a.75.75 0 01-1.06 0z"
                    >
                    </path>
                </svg>
            </div>
        </div>
        <transition
            enter-active-class="duration-300 ease-out"
            enter-from-class="transform opacity-0 scale-75"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="duration-200 ease-in"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-75"
        >
            <div class="border-b-2 border-asoc-gold-lighter shadow-md py-4 px-6" v-show="show">
                <slot name="content" />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: 'white',
        },
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        toggle() {
            this.show = !this.show;
            this.show ? this.$refs.chevron.classList.add('rotate-180') : this.$refs.chevron.classList.remove('rotate-180');
        },
    },
    computed: {
        colorClass() {
            return {
                white: 'bg-asoc-white border-2 border-asoc-gold-lighter text-black',
                black: 'bg-asoc-black border-2 border-asoc-gold-lighter text-white',
            }[this.color];
        },
        svgClass() {
            return {
                white: 'fill-current text-black',
                black: 'fill-current text-white',
            }[this.color];
        },
    },
};
</script>
