<template>
    <base-accordion v-for="accordion in accordions" :key="accordion" :color="color">
        <template v-slot:title>
            {{ accordion.title }}
        </template>
        <template v-slot:content>
            <span v-html="accordion.content"></span>
        </template>
    </base-accordion>
</template>

<script>
import BaseAccordion from '@/components/BaseAccordion.vue';

export default {
    components: {
        BaseAccordion,
    },
    props: {
        accordions: {
            type: Array,
            default() {
                return [];
            },
        },
        color: {
            type: String,
            default: 'white',
        },
    },
};
</script>
