<!-- eslint-disable max-len -->
<template>
    <!-- transparent -->
    <img
        draggable="false"
        src="/images/logo.png"
        v-bind="$attrs"
        v-if="type === 'transparent'"
    >

    <!-- circle -->
    <svg
        :class="['fill-current', svgColor]"
        v-bind="$attrs"
        viewBox="0 0 132.5 132.5"
        xmlns="http://www.w3.org/2000/svg"
        v-else-if="type === 'circle'"
    >
        <path d="m132.44 66.218a66.218 66.218 0 0 1-66.218 66.218 66.218 66.218 0 0 1-66.218-66.218 66.218 66.218 0 0 1 66.218-66.218 66.218 66.218 0 0 1 66.218 66.218z" stroke-width=".26458"/>
        <g transform="matrix(.87823 0 0 .91108 -28.458 -20.611)" fill="#fff" stroke-width=".21582" aria-label="@">
            <path d="m152.4 90.387c-1.8345-21.798-18.885-39.712-44.352-39.712-22.985 0-40.683 14.136-44.352 36.69-2.8057 16.942 2.9136 31.079 14.784 40.683 13.273 10.683 35.503 11.331 48.452 4.964l-2.5899-6.3668c-14.892 6.2589-30.647 4.6402-41.546-3.9927-10.036-7.8776-14.676-19.748-12.41-34.1 3.8848-23.309 22.122-30.971 37.661-30.971 21.367 0 35.611 12.41 37.553 33.453 0.75539 8.2013-0.43165 16.295-3.8848 20.072-2.9136 3.2374-6.2589 3.1294-8.525 1.187-3.3453-2.9136-3.5611-11.547-3.5611-17.374v-24.28h-6.9064v5.6114c-3.9928-3.9927-9.3884-6.3668-15.863-6.3668-13.597 0-23.417 10.683-23.417 24.28 0 13.597 9.82 24.388 23.417 24.388 7.4459 0 13.597-3.2374 17.59-8.4171 0.75538 2.8057 2.0503 5.2877 3.8848 7.0143 5.6114 5.3956 14.029 3.1294 18.129-1.4029 4.856-5.2877 6.7984-15.216 5.9352-25.359zm-45.539 21.259c-9.82 0-16.295-7.6618-16.295-17.482 0-9.82 6.4747-17.482 16.295-17.482s15.647 7.6618 15.647 17.482c0 9.82-5.8272 17.482-15.647 17.482z"/>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            validator: (value) => ['transparent', 'circle'].indexOf(value) !== 1,
            default: 'transparent',
        },
        color: {
            type: String,
            default: 'black',
        },
    },
    computed: {
        svgColor() {
            return {
                black: 'text-asoc-black',
                gold: 'text-asoc-gold-lighter',
                red: 'text-red-500',
                green: 'text-green-600',
                blue: 'text-blue-600',
            }[this.color];
        },
    },
};
</script>
