import { auth } from '../middleware/auth';

export default [
    {
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../../views/Dashboard/Index.vue'),
        children: [
            {
                path: '',
                name: 'dashboard-home',
                component: () => import(/* webpackChunkName: "dashboard" */ '../../views/Dashboard/Home/Index.vue'),
                meta: {
                    title: 'Dashboard',
                    subscriptionRequired: true,
                },
            },
            {
                path: 'subscription',
                name: 'dashboard-subscription',
                component: () => import(/* webpackChunkName: "dashboard-subscription" */ '../../views/Dashboard/Subscription/Index.vue'),
                meta: {
                    title: 'Subscription',
                    subscriptionRequired: true,
                },
            },
            {
                path: 'settings',
                name: 'dashboard-settings',
                component: () => import(/* webpackChunkName: "dashboard-settings" */ '../../views/Dashboard/Settings/Index.vue'),
                meta: {
                    title: 'Settings',
                },
            },
        ],
        beforeEnter: auth,
        meta: {
            title: 'Dashboard',
        },
    },
];
