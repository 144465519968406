<template>
    <transition
        enter-active-class="duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transform -translate-y-16 duration-300"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            ref="root"
            role="alert"
            v-show="isActive"
            :class="['overflow-hidden rounded-lg p-4 mt-4 pointer-events-auto', colorClass]"
            @mouseover="toggleTimer(true)"
            @mouseleave="toggleTimer(false)"
            @click="whenClicked"
        >
            <p v-html="message"></p>
        </div>
    </transition>
</template>

<script>
import { defineComponent, render } from 'vue';
import { removeElement } from './helpers';
import Timer from './timer';

export default defineComponent({
    name: 'toast',
    props: {
        message: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'success',
        },
        duration: {
            type: Number,
            default: 3000,
        },
        dismissible: {
            type: Boolean,
            default: true,
        },
        queue: Boolean,
        pauseOnHover: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isActive: false,
            parent: null,
            isHovered: false,
        };
    },
    beforeMount() {
        this.setupContainer();
    },
    mounted() {
        this.showNotice();
    },
    methods: {
        setupContainer() {
            // If parent is already created then return setup
            this.parent = document.querySelector('#toast');
            if (this.parent) return;
            // Create our parent since it doesn't exist
            this.parent = document.createElement('div');
            this.parent.setAttribute('id', 'toast');
            this.parent.className = 'fixed inset-0 p-3 z-50 flex flex-col items-center pointer-events-none';
            // Append to body
            const container = document.body;
            container.appendChild(this.parent);
        },

        shouldQueue() {
            if (!this.queue) return false;
            // Check if an element is in the parent already
            return (
                this.parent.childElementCount > 0
            );
        },

        dismiss() {
            // Stop our timer
            if (this.timer) this.timer.stop();
            clearTimeout(this.queueTimer);
            this.isActive = false;
            // Timeout for the animation complete before destroying
            setTimeout(() => {
                const wrapper = this.$refs.root;
                // Remove our component from the DOM
                render(null, wrapper);
                removeElement(wrapper);
            }, 300);
        },

        showNotice() {
            if (this.shouldQueue()) {
                this.queueTimer = setTimeout(this.showNotice, 300);
                return;
            }
            // Get and remove the pending div while transfering toast to parent
            const wrapper = this.$refs.root.parentElement;
            this.parent.insertAdjacentElement('afterbegin', this.$refs.root);
            removeElement(wrapper);
            // Set notice to active
            this.isActive = true;
            // Set the timer
            if (this.duration) {
                this.timer = new Timer(this.dismiss, this.duration);
            }
        },

        whenClicked() {
            if (!this.dismissible) return;
            this.dismiss();
        },

        toggleTimer(newVal) {
            if (!this.pauseOnHover || !this.timer) return;
            newVal ? this.timer.pause() : this.timer.resume();
        },
    },
    computed: {
        colorClass() {
            return {
                success: 'text-white font-semibold bg-green-600',
                error: 'text-white font-semibold bg-red-500',
                info: 'text-white font-semibold bg-blue-600',
                warn: 'text-black font-semibold bg-yellow-500',
            }[this.type];
        },
    },
});
</script>
