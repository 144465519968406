<template>
    <section class="my-10">
        <div class="flex flex-col items-center text-black">
            <h1 class="text-5xl font-extrabold mb-4 text-center">No hidden fees.</h1>
            <h2 class="text-5xl font-extrabold mb-4">No gimmicks.</h2>
            <div class="relative rounded-2xl lg:w-2/6 mt-10 border-2 border-asoc-gold-lighter shadow-lg">
                <div class="absolute bg-gray-300 rounded-full transform translate-x-1/2 -top-6 right-1/2">
                    <h1 class="text-3xl font-bold py-2 px-4">1 Device</h1>
                </div>
                <div class="p-12 text-center space-y-16">
                    <div>
                        <div class="inline-flex flex-row items-center space-x-4 font-bold">
                            <span>USD</span>
                            <h1 class="text-7xl">5.00</h1>
                            <span>/mo</span>
                        </div>
                        <div class="inline-flex flex-col">
                            <span class="font-bold">+ $2.00 / extra device</span>
                            <span class="italic mb-4">*Sales tax may apply</span>
                            <p>
                                One flat rate, no hidden fees - we aren't kidding.
                                Industry competitive pricing, industry-leading service.
                                Stop sacrificing quality for currency.
                            </p>
                        </div>
                    </div>
                    <div class="pb-1">
                        <app-button color="black" buttonStyle="big" is="router" href="/checkout">
                            <p>Get Started</p>
                        </app-button>
                    </div>
                    <span class="font-medium italic">Take charge of your privacy, it's just that easy.</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AppButton from '@/components/AppButton.vue';

export default {
    components: {
        AppButton,
    },
};
</script>
