import { h, render } from 'vue';

export function removeElement(el) {
    if (typeof el.remove !== 'undefined') {
        el.remove();
    } else {
        el.parentNode.removeChild(el);
    }
}

export function createComponent(component, props, parentContainer, slots = {}) {
    const vNode = h(component, props, slots);
    const container = document.createElement('div');
    // Set attributes for our pending container
    container.setAttribute('id', 'toast-pending');
    container.className = 'hidden';
    parentContainer.appendChild(container);
    // Render to DOM
    render(vNode, container);
    // Return our component
    return vNode.component;
}
