<template>
    <a :href="href" v-bind="$attrs" v-if="is === 'link'">
        <div :class="[posClass, colorClass]">
            <slot />
        </div>
    </a>

    <router-link :to="href" v-else-if="is === 'router'">
        <div :class="[posClass, colorClass]">
            <slot />
        </div>
    </router-link>

    <button v-else
        :type="type"
        :class="[posClass, colorClass]"
        v-bind="$attrs"
        @click="this.$emit('click')"
    >
        <slot />
    </button>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        color: {
            type: String,
            default: 'black',
        },
        type: {
            type: String,
            default: 'submit',
        },
        href: {
            type: [String, Object],
            default: null,
        },
        is: {
            type: String,
            default: 'button',
        },
        buttonStyle: {
            type: String,
            default: 'regular',
        },
    },
    emits: ['click'],
    data() {
        return {
            posClass: {
                regular: 'text-center px-4 py-2 rounded-full font-bold transition-colors ease-in-out duration-150 disabled:bg-gray-400 disabled:opacity-50',
                big: 'text-center w-full px-6 py-3 text-center rounded-full text-3xl font-bold transition-colors ease-in-out duration-150 disabled:bg-gray-400 disabled:opacity-50',
            }[this.buttonStyle],
        };
    },
    computed: {
        colorClass() {
            return {
                white: 'text-black bg-asoc-white hover:bg-gray-200 active:bg-gray-400',
                gray: 'text-black bg-gray-300 hover:bg-gray-400 active:bg-gray-600',
                black: 'text-white bg-asoc-black-lighter hover:bg-gray-700 active:bg-gray-800',
                red: 'text-white bg-red-500 hover:bg-red-600 active:bg-red-700',
                blue: 'text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-700',
                green: 'text-white bg-green-600 hover:bg-green-500 active:bg-green-700',
                gold: 'text-white bg-asoc-gold hover:bg-asoc-gold-lighter active:bg-asoc-gold-lighter',
            }[this.color];
        },
    },
};
</script>
