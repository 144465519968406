import ToastComponent from './js/Component.vue';
import useToast from './js/api';

const ToastPlugin = {
    install: (app, options = {}) => {
        const instance = useToast(options);
        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$toast = instance;
        app.provide('$toast', instance);
    },
};

export default ToastPlugin;
export { useToast, ToastComponent };
