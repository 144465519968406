<template>
    <section class="mb-8">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3
        gap-4 justify-items-center items-baseline text-black text-center">
            <div>
                <img draggable="false" class="mx-auto w-60" src="/images/home/features_privacy.svg" />
                <h1 class="text-3xl font-bold">General Privacy</h1>
                <p>
                    Don't allow external parties to pinpoint and selfishly benefit from your online ventures.
                    Take back your rights of privacy and security
                    by using a VPN that you can trust.
                </p>
            </div>
            <div>
                <img draggable="false" class="mx-auto w-60" src="/images/home/features_isp_tracking.svg" />
                <h1 class="text-3xl font-bold">ISP Tracking</h1>
                <p>
                    Your own internet service provider (ISP) can legally sell
                    your data to other third-party entities. Without a VPN, all
                    of your online activity is first routed through and transmitted by
                    your ISP, potentially exposing everything from search results and sites visited to purchases.
                </p>
            </div>
            <div>
                <img draggable="false" class="mx-auto w-60" src="/images/home/features_poly_location.svg" />
                <h1 class="text-3xl font-bold">Global Access</h1>
                <p>
                    Establish a connection to the server optimized best for your location.
                    With our ever-growing server location list, you won't
                    have to suffer from high ping or slow network speeds while being on a VPN.
                    Stay protected without any negative impact.
                </p>
            </div>
            <div>
                <img draggable="false" class="mx-auto w-60" src="/images/home/features_bypass_censorship.svg" />
                <h1 class="text-3xl font-bold">Bypass Censorship</h1>
                <p>
                    Certain internet providers or online services can censor or throttle your access
                    to restricted content based on region/location.
                    Bypass these restrictions with ease by using a VPN.
                </p>
            </div>
            <div>
                <img draggable="false" class="mx-auto w-60" src="/images/home/features_data_protection.svg" />
                <h1 class="text-3xl font-bold">Data Security</h1>
                <p>
                    Malicious hackers can intercept and steal your online data when communicating
                    through an insecure connection. Prevent digital attackers and data hijacking
                    by using an encrypted, secure tunnel with a VPN.
                </p>
            </div>
            <div>
                <img draggable="false" class="mx-auto w-60" src="/images/home/features_profiling.svg" />
                <h1 class="text-3xl font-bold">Big Tech Profiling</h1>
                <p>
                    Big tech companies such as Google, Facebook, Amazon, and many others
                    monitor your online activity in order to build a profile surrounding
                    you and your personal information. Stop these digital predators from
                    tracking your online endeavors by masking your internet traffic with a VPN.
                </p>
            </div>
        </div>
    </section>
    <!-- Divider -->
    <div class="border-t border-gray-300"></div>
    <section class="mt-8 mb-4">
        <div class="grid grid-cols-1 sm:grid-cols-2
        gap-4 justify-items-center items-baseline text-black text-center">
            <div>
                <img draggable="false" class="mx-auto w-72" src="/images/home/features_servers.svg" />
                <h1 class="text-3xl font-bold">+12Tb/s of DDoS Protection</h1>
                <h1 class="text-3xl font-bold">10Gb/s Dedicated Ports</h1>
                <div class="w-full md:w-3/4 flex flex-col gap-4 mt-2 mx-auto">
                    <p>
                        With more than 12Tb/s of advanced DDoS protection
                        you'll never have to worry about being taken offline.
                    </p>
                    <p>
                        Being a part of a premium network, we have dedicated
                        10Gb/s symmetric network speeds (upload & download).
                        Your network connectivity speeds will never be limited by the bandwidth of our servers.
                    </p>
                </div>
            </div>
            <div class="flex flex-col items-center gap-2">
                <img draggable="false" class="mx-auto w-96 mb-2 rounded-md border-2
                border-asoc-gold-lighter drop-shadow-md"
                src="/images/home/features_modules.png" />
                <div class="flex items-center justify-center gap-4">
                    <h1 class="w-min lg:w-auto text-3xl font-bold">Introducing Modules</h1>
                    <div class="bg-asoc-gold-lighter rounded-full">
                        <h1 class="text-xl font-bold py-1 px-3">Beta</h1>
                    </div>
                </div>
                <div class="w-full md:w-3/4 flex flex-col gap-4">
                    <p>
                        Utilizing our foundation for a desktop application, you can create any module you
                        desire within the aSocket client. The possibilities are endless!
                    </p>
                    <p>
                        Browse the web in a secure, private window via the <b>search module</b>; generate passwords,
                        numbers, and strings with the <b>random module</b>; create alarms/timers/stopwatches, convert
                        to/from unix, and monitor global time differences with the <b>time module</b> - these are just
                        a few examples of the many capabilities already implemented into the client via modules.
                    </p>
                    <p class="text-lg text-asoc-gold underline">
                        <a href="https://github.com/aSocket/Modules" target="_blank">
                            Learn More
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    //
};
</script>
