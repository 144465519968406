import store from '../../store';

export function auth(to, from, next) {
    // Check if auth user is banned or not active
    if (store.state.auth.status.code !== 'ACTIVE') {
        next('/');
        return;
    }
    // Check if user is logged in
    if (!store.getters.isLoggedIn) {
        next('login');
    } else {
        next();
    }
}

export function guest(to, from, next) {
    if (store.getters.isLoggedIn) {
        next('/');
    } else {
        next();
    }
}

export function noSub(to, from, next) {
    if (store.getters.hasSubscription) {
        next({ name: 'dashboard-subscription' });
    } else {
        next();
    }
}

export function admin(to, from, next) {
    if (!store.getters.isAdmin) {
        next('/');
    } else {
        next();
    }
}
