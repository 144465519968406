<template>
    <div class="h-full w-full">
        <div id="modal-portal"></div>
        <router-view />
    </div>
</template>

<script>
import { computed } from 'vue';
import { mapState } from 'vuex';

export default ({
    computed: {
        ...mapState(['auth']),
    },
    provide() {
        return {
            user: computed(() => this.auth.user),
        };
    },
});
</script>
