/* eslint-disable no-shadow */
import axios from 'axios';

const state = {
    token: localStorage.getItem('userToken') ? `Bearer ${localStorage.getItem('userToken')}` : null,
    user: null,
    status: { code: 'ACTIVE' },
};
const getters = {
    isAuthenticated: (state) => !!state.user,
    isAdmin: (state) => state.user && Boolean(state.user.is_admin),
    isLoggedIn: (state) => !!state.token,
    hasSubscription: (state) => state.user && !!state.user.subscription,
};
const actions = {
    async authAction({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            // Request token
            axios.post(data.path, data.form)
                .then(async (res) => {
                    if (res.data.complete) {
                        commit('setToken', res.data.token);
                        // Set axios auth header
                        axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
                        // Dispatch user request
                        await dispatch('getUser');
                    }

                    return resolve(res);
                })
                .catch((err) => {
                    commit('removeToken');
                    return reject(err);
                });
        });
    },
    async logout({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.post('users/logout')
                .then((res) => {
                    // Set status
                    commit('setStatus', { code: 'ACTIVE' });
                    // Remove login token
                    commit('removeToken');
                    // Send our promise off!
                    return resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    async getUser({ commit }) {
        if (!this.getters.isLoggedIn) { return null; }
        return new Promise((resolve, reject) => {
            if (!this.getters.isLoggedIn) {
                resolve(null);
                return;
            }
            axios.get('users/')
                .then((e) => {
                    commit('setUser', e.data);
                    resolve(e.data);
                })
                .catch((err) => {
                    if (!err.response) {
                        reject(err);
                        return;
                    }
                    if (err.response.status === 401) {
                        switch (err.response.data.code) {
                            case 'E_UNACTIVATED_ACCOUNT':
                                commit('setStatus', { code: 'UNACTIVATED', message: err.response.data.message });
                                break;
                            case 'E_TERMINATED_ACCOUNT':
                                commit('setStatus', { code: 'TERMINATED', message: err.response.data.message });
                                break;
                            default:
                                commit('removeToken');
                        }
                    }
                    resolve(err);
                });
        });
    },
};
const mutations = {
    setUser(state, user) {
        state.user = user;
        state.status.code = user.status;
    },
    setToken(state, token) {
        localStorage.setItem('userToken', token);
        state.token = token;
    },
    setStatus(state, status) {
        state.status = status;
    },
    setSubscription(state, subscription) {
        if (!state.user) { return; }
        state.user.subscription = subscription;
    },
    removeToken(state) {
        localStorage.removeItem('userToken');
        state.token = null;
        state.user = null;
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
