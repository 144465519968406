<template>
    <footer class="mt-auto bg-asoc-black py-8 px-16 space-y-4">
        <div class="border-b-2 border-gray-600">
            <div class="mb-4">
                <router-link to="/" class="flex items-center flex-row">
                    <app-logo class="mr-1 h-10 w-10" />
                    <h1 class="text-xl font-bold">aSocket</h1>
                </router-link>
            </div>
        </div>
        <div class="grid justify-items-stretch gap-4 sm:justify-items-start grid-cols-2 sm:grid-cols-4">
            <div v-for="(links, title) in navLinks" :key="title">
                <span class="text-gray-500 font-bold uppercase">{{ title }}</span>
                <ul class="mt-4 space-y-3">
                    <li class="font-semibold" v-for="link in links" :key="link">
                        <div class="flex items-center flex-row" v-if="link.icon">
                            <img class="h-5 w-5 mr-2" :src="link.icon" />
                            <app-nav-link :is="link.type" :href="link.link" v-if="link.icon">
                                {{ link.title }}
                            </app-nav-link>
                        </div>
                        <app-nav-link :is="link.type" :href="link.link" v-else>
                            {{ link.title }}
                        </app-nav-link>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Company Information -->
        <div class="relative">
            <div class="flex flex-col">
                <span class="text-sm font-bold text-gray-500">
                    Copyright &copy; 2021-{{ new Date().getFullYear() }} aSocket LLC
                </span>
                <span class="text-sm font-semibold text-gray-500">
                    5900 Balcones Drive, Suite 100, Austin, TX, 78731, USA
                </span>
                <span class="text-sm font-semibold text-gray-500">
                    Operating AS207600
                </span>
            </div>

            <!-- < md:Divider -->
            <div class="my-4 border-b-2 border-gray-600 md:border-b-0 md:my-0"></div>

            <!-- Trustpilot -->
            <div class="md:absolute md:bottom-0 md:right-0">
                <a class="flex justify-center" href="https://www.trustpilot.com/review/asocket.net" target="_blank">
                    See our reviews on
                    <img class="w-5 ml-1 mr-0.5" src="/images/icons/trustpilot.svg">
                    Trustpilot
                </a>
            </div>
        </div>

    </footer>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue';
import AppNavLink from '@/components/AppNavLink.vue';

export default {
    components: {
        AppLogo,
        AppNavLink,
    },
    data() {
        return {
            navLinks: {
                aSocket: [
                    {
                        title: 'Purchase',
                        type: 'router',
                        link: '/checkout',
                    },
                    {
                        title: 'Download',
                        type: 'router',
                        link: '/download',
                    },
                    {
                        title: 'Affiliate Program',
                        type: 'router',
                        link: '/apply/affiliate',
                    },
                    {
                        title: 'Contact',
                        type: 'router',
                        link: '/contact',
                    },
                ],
                Community: [
                    {
                        title: 'Discord',
                        type: 'link',
                        link: '/discord',
                        icon: '/images/branding/discord/white.svg',
                    },
                    {
                        title: 'GitHub',
                        type: 'link',
                        link: 'https://github.com/aSocket',
                        icon: '/images/branding/github/white.svg',
                    },
                    {
                        title: 'Twitter',
                        type: 'link',
                        link: 'https://twitter.com/teamasocket',
                        icon: '/images/branding/twitter/white.svg',
                    },
                    {
                        title: 'TikTok',
                        type: 'link',
                        link: 'https://www.tiktok.com/@asocket',
                        icon: '/images/branding/tiktok/white.svg',
                    },
                ],
                Legal: [
                    {
                        title: 'Terms of Service',
                        type: 'router',
                        link: '/legal/terms',
                    },
                    {
                        title: 'Privacy Policy',
                        type: 'router',
                        link: '/legal/privacy',
                    },
                ],
                Help: [
                    {
                        title: 'support@asocket.net',
                        type: 'link',
                        link: 'mailto:support@asocket.net?subject=General%20Support', // In the future this will more than likely be a simple contact page w the option of email.
                    },
                ],
            },
        };
    },
};
</script>
