<template>
    <div :class="['flex flex-col h-full min-h-screen text-white', background]">
        <!-- Navbar -->
        <app-nav class="z-20" :navLinks="navLinks" />
        <transition
            enter-active-class="duration-500 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
        >
            <main v-show="showContent" :class="{ 'min-w-0 px-4 sm:px-6 xl:px-8 md:container md:mx-auto': container }">
                <slot></slot>
            </main>
        </transition>
        <app-footer />
    </div>
</template>

<script>
import AppNav from './AppNav.vue';
import AppFooter from './AppFooter.vue';

export default {
    components: {
        AppNav,
        AppFooter,
    },
    props: {
        background: {
            type: String,
            default: 'bg-asoc-black',
        },
        container: {
            type: Boolean,
            default: true,
        },
        navLinks: {
            type: Object,
            default() {
                return {
                    Home: {
                        type: 'router',
                        link: '/',
                    },
                };
            },
        },
    },
    data() {
        return {
            showContent: false,
        };
    },
    mounted() {
        this.showContent = true;
    },
};
</script>
