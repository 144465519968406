<template>
    <nav class="bg-asoc-black shadow-md">
        <!-- Primary Navigation Menu -->
        <div class="md:container md:mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16 align-middle">
                <!-- Logo -->
                <div class="flex">
                    <div class="flex-shrink-0 flex items-center">
                        <router-link to="/" class="flex items-center flex-row">
                            <app-logo class="mr-1 h-10 w-10" />
                            <h1 class="text-lg font-bold pr-6 sm:border-r-2">aSocket</h1>
                        </router-link>
                    </div>
                    <!-- Navigation Links -->
                    <div class="hidden space-x-8 sm:-my-px sm:ml-6 sm:flex">
                        <app-nav-link v-for="(obj, name) in navLinks" :key="name" :href="obj.link" :is="obj.type">
                            {{ name }}
                        </app-nav-link>
                    </div>
                </div>

                <div class="hidden space-x-8 sm:-my-px sm:ml-6 sm:flex">
                    <template v-if="accountStatus">
                        <app-nav-link @click="$store.dispatch('logout')">
                            <div class="bg-red-500 px-4 py-2 rounded-full">
                                Logout
                            </div>
                        </app-nav-link>
                    </template>
                    <template v-else>
                        <template v-if="user">
                            <app-nav-link href="/admin" is="router" v-if="user.is_admin">
                                Admin
                            </app-nav-link>
                            <app-nav-link href="/dashboard" is="router">
                                <div class="bg-asoc-white px-4 py-2 rounded-full text-black">
                                    Dashboard
                                </div>
                            </app-nav-link>
                        </template>
                        <template v-else>
                            <app-nav-link href="/login" is="router">
                                Login
                            </app-nav-link>
                            <app-nav-link href="/register" is="router">
                                <div class="bg-asoc-white px-4 py-2 rounded-full text-black">
                                    Register
                                </div>
                            </app-nav-link>
                        </template>
                    </template>
                </div>
                <!-- Hamburger -->
                <div class="-mr-2 flex items-center sm:hidden">
                    <button
                        class="inline-flex items-center justify-center p-2 rounded-md text-white
                        hover:text-gray-400 hover:bg-asoc-black-lighter focus:outline-none focus:bg-asoc-black-lighter
                        focus-within:focus:text-gray-500 transition duration-150 ease-in-out"
                        @click="showingNavigationDropdown = ! showingNavigationDropdown">
                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path
                            :class="{
                                'hidden': showingNavigationDropdown,
                                'inline-flex': ! showingNavigationDropdown
                            }"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                            <path
                            :class="{
                                'hidden': ! showingNavigationDropdown,
                                'inline-flex': showingNavigationDropdown
                            }"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="flex justify-center mb-4" v-if="accountStatus">
                <app-segment posClass="flex-grow" :color="accountStatus.color">
                    {{ this.$store.state.auth.status.message }}
                </app-segment>
            </div>
        </div>
        <!-- Responsive Navigation Menu -->
        <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}" class="sm:hidden">
            <div class="pt-2 pb-3 space-y-1 text-center">
                <app-nav-link
                    v-for="(obj, name) in navLinks"
                    :key="name"
                    :href="obj.link"
                    :is="obj.type"
                    :mobile="true"
                >
                    {{ name }}
                </app-nav-link>
            </div>
            <!-- Profile Links -->
            <div class="border-t border-gray-200">
                <div class="pt-2 pb-3 space-y-1 text-center">
                    <template v-if="accountStatus">
                        <app-nav-link :mobile="true" @click="$store.dispatch('logout')">
                            <div class="bg-red-500 px-4 py-2 rounded-full">
                                Logout
                            </div>
                        </app-nav-link>
                    </template>
                    <template v-else>
                        <template v-if="user">
                            <app-nav-link href="/admin" is="router" v-if="user.is_admin">
                                Admin
                            </app-nav-link>
                            <app-nav-link href="/dashboard" is="router" :mobile="true">
                                <div class="bg-asoc-white px-4 py-2 rounded-full text-black">
                                    Dashboard
                                </div>
                            </app-nav-link>
                        </template>
                        <template v-else>
                            <app-nav-link href="/login" is="router" :mobile="true">
                                Login
                            </app-nav-link>
                            <app-nav-link href="/register" is="router" :mobile="true">
                                <div class="bg-asoc-white px-4 py-2 rounded-full text-black">
                                    Register
                                </div>
                            </app-nav-link>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue';
import AppNavLink from '@/components/AppNavLink.vue';
import AppSegment from '@/components/AppSegment.vue';

export default {
    components: {
        AppLogo,
        AppNavLink,
        AppSegment,
    },
    props: {
        navLinks: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    inject: ['user'],
    data() {
        return {
            showingNavigationDropdown: false,
        };
    },
    computed: {
        accountStatus() {
            if (!this.$store.state.auth.status) { return null; }
            return {
                UNACTIVATED: {
                    code: 'UNACTIVATED',
                    color: 'bg-asoc-gold',
                },
                TERMINATED: {
                    code: 'TERMINATED',
                    color: 'bg-red-500',
                },
            }[this.$store.state.auth.status.code];
        },
    },
};
</script>
