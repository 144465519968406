/* eslint-disable no-shadow */
import axios from 'axios';

const state = {
    data: {},
    status: 'LOADING',
};
const getters = {
};
const actions = {
    async fetchTokens({ commit }) {
        axios.get('/users/tokens')
            .then((res) => {
                commit('setTokens', res.data);
            });
    },
};
const mutations = {
    setTokens(state, payload) {
        state.data = payload;
        state.status = 'ACTIVE';
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
