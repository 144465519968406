/* eslint-disable no-shadow */
import axios from 'axios';

const state = {
    products: [],
    pricing: {
        subtotal: 0,
        total: 0,
        salesTax: 0,
    },
    promotionCode: null,
    salesTaxRate: 8.25,
};
const getters = {
    getProductByName: (state) => (name) => state.products.find((product) => product.name === name),
    hasProducts(state) {
        return state.products.length > 0;
    },
};
const actions = {
    /**
     * Get current prices depending on the current dataset
     * @param {Object} params { extraTokens, promotion }
     */
    async getPrices({ commit }, params = {}) {
        return new Promise((resolve) => {
            axios.get('/payments/pricing', {
                params,
            }).then(async (res) => {
                if (!res.data) { return; }
                const { data } = res;

                // We don't always receive info from server so check if it exists
                if (data.info) {
                    data.info.products = data.info.products.map((product) => ((product.type === 'number')
                        ? { ...product, quantity: 0 }
                        : { ...product }));
                }

                // Run mutations
                commit('setPricingData', data);
                commit('setPromotionCode', params.promotion);

                // Resolve promise with our data
                resolve(data);
            });
        });
    },
    doCheckout({ state, dispatch }, paymentNonce) {
        return new Promise((resolve, reject) => {
            const extraTokens = state.products.find((product) => product.name === 'Extra Token');

            axios.post('/payments/checkout', {
                paymentNonce,
                promotionCode: state.promotionCode,
                extraTokens: extraTokens.quantity || 0,
            }).then((res) => {
                dispatch('getUser');
                resolve(res);
            }).catch((err) => {
                reject(err);
            });
        });
    },
};
const mutations = {
    setPricingData(state, data) {
        // Set the pricing data sent from the api
        state.pricing = data.pricing;
        // We don't always receive info, so we need to check for it
        if (data.info) {
            state.salesTaxRate = data.info.salesTaxRate;
            state.products = data.info.products;
        }
    },
    setPromotionCode(state, code) {
        state.promotionCode = code;
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
