import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
// Store
import store from '../store';
// Middleware
import {
    auth,
    noSub,
} from './middleware/auth';
// Components
import Error from '../views/Error.vue';
import Home from '../views/Home/Index.vue';
// Routes
import adminRoutes from './routes/admin';
import authRoutes from './routes/auth';
import dashboardRoutes from './routes/dashboard';
import legalRoutes from './routes/legal';

const routes = [
    ...authRoutes,
    ...dashboardRoutes,
    ...legalRoutes,
    // #region Errors
    {
        path: '/:catchAll(.*)',
        component: Error,
        name: 'not-found',
        props: {
            status: 404,
        },
        meta: {
            title: '404',
        },
    },
    {
        path: '/coming-soon',
        component: Error,
        name: 'coming-soon',
        props: {
            status: 0,
        },
        meta: {
            title: 'Coming Soon',
        },
    },
    // #endregion
    // #region Homepage
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/download',
        name: 'download',
        component: () => import(/* webpackChunkName: "download" */ '../views/Download.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    },
    // #endregion
    // #region Forms
    {
        path: '/apply/:form',
        name: 'apply',
        component: () => import(/* webpackChunkName: "apply" */ '../views/Forms/Index.vue'),
        meta: {
            title: 'Applications',
        },
    },
    // #endregion
    // #region Payment routes
    {
        path: '/checkout',
        component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout/Index.vue'),
        children: [
            {
                path: ':coupon?',
                name: 'checkout',
                component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout/CheckoutSummary.vue'),
            },
            {
                path: 'payment',
                name: 'checkout-payment',
                component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout/CheckoutPayment.vue'),
                beforeEnter: auth,
            },
        ],
        beforeEnter: noSub,
        meta: {
            title: 'Checkout',
        },
    },
    // #endregion
    // #region External Links
    {
        path: '/discord',
        beforeEnter(to, from, next) {
            window.location.replace('https://discord.gg/xfATrzWDAn');
        },
    },
    {
        path: '/github',
        beforeEnter(to, from, next) {
            window.location.replace('https://github.com/aSocket');
        },
    },
    {
        path: '/wiki',
        name: 'wiki',
        redirect: '/coming-soon',
    },
    // #endregion
];

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
    routes,
});

// Admin router
router.beforeEach((to) => {
    if (!store.getters.isAdmin) { return; }
    if (!router.hasRoute('admin')) {
        adminRoutes.forEach((route) => router.addRoute(route));
        // eslint-disable-next-line consistent-return
        return to.fullPath;
    }
});

// Dynamic page title
const defaultTitle = process.env.VUE_APP_TITLE;
router.afterEach((to, from) => {
    nextTick(() => {
        document.title = to.meta.title
            ? `${to.meta.title} | ${defaultTitle}`
            : defaultTitle;
    });
});

export default router;
