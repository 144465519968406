import { guest } from '../middleware/auth';

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '../../views/Login.vue'),
        beforeEnter: guest,
        meta: {
            title: 'Login',
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth" */ '../../views/Register.vue'),
        beforeEnter: guest,
        meta: {
            title: 'Register',
        },
    },
    {
        path: '/verify/:email',
        name: 'verify',
        component: () => import(/* webpackChunkName: "auth" */ '../../views/Verify.vue'),
        meta: {
            title: 'Verification',
        },
    },
    {
        path: '/reset-password/:token?',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "auth" */ '../../views/ResetPassword.vue'),
        meta: {
            title: 'Password Reset',
        },
    },
];
