export default [
    {
        path: '/legal',
        name: 'legal',
        component: () => import(/* webpackChunkName: "legal" */ '../../views/Legal/Index.vue'),
        children: [
            {
                path: 'terms',
                alias: '',
                name: 'terms',
                component: () => import(/* webpackChunkName: "legal" */ '../../views/Legal/Terms.vue'),
                meta: {
                    title: 'Terms of Service',
                },
            },
            {
                path: 'terms/vpn',
                name: 'terms-vpn',
                component: () => import(/* webpackChunkName: "legal" */ '../../views/Legal/TermsVPN.vue'),
                meta: {
                    title: 'Terms of Service - aSocket VPN',
                },
            },
            {
                path: 'terms/subscription',
                name: 'terms-subscription',
                component: () => import(/* webpackChunkName: "legal" */ '../../views/Legal/TermsSubscription.vue'),
                meta: {
                    title: 'Terms of Service - Subscription',
                },
            },
            {
                path: 'privacy',
                name: 'privacy',
                component: () => import(/* webpackChunkName: "legal" */ '../../views/Legal/Privacy.vue'),
                meta: {
                    title: 'Privacy Policy',
                },
            },
            {
                path: 'privacy/vpn',
                name: 'privacy-vpn',
                component: () => import(/* webpackChunkName: "legal" */ '../../views/Legal/PrivacyVPN.vue'),
                meta: {
                    title: 'Privacy Policy - aSocket VPN',
                },
            },
            {
                path: 'privacy/gdpr',
                name: 'privacy-gdpr',
                component: () => import(/* webpackChunkName: "legal" */ '../../views/Legal/PrivacyGDPR.vue'),
                meta: {
                    title: 'Privacy Policy - GDPR',
                },
            },
        ],
        meta: {
            title: 'Terms',
        },
    },
];
