import { createStore } from 'vuex';
import auth from './modules/auth';
import checkout from './modules/checkout';
import token from './modules/token';

export default createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        checkout,
        token,
    },
});
